<template>
  <div contenteditable="false" class="introduction cke_editable cke_editable_themed cke_contents_ltr cke_show_borders" spellcheck="false">
      <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="max-width: 100%;margin-block-start: 0px; margin-block-end:0px ; padding:10px 0 10px 0;min-height: 1em; white-space: pre-wrap; color: rgb(137, 137, 137); text-align: center; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
            style="color: rgb(43, 43, 43); line-height: 28px;letter-spacing:1px;font-size:18px;font-weight:bold;font-family: PingfangSC-LIGHT,sans-serif;">中国宋庆龄青少年科技文化交流中心<br />物业安全网格化信息系统介绍</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin:10px 20px 20px 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;"><strong
                style="background: linear-gradient(to right,rgb(73, 200, 149),rgb(38, 198, 218));color: white;white-space: pre-wrap;border-width: 0.25em 0;display: inline;font-weight: normal;padding: 2px 4px 2px 4px;">物业安全网格化信息系统</strong>由中国宋庆龄青少年科技文化交流中心（以下简称“中心”）物业安全网格化管理工作领导小组全面领导进行开发，目的为全面推进中心物业安全管理工作精细化、标准化、制度化建设，夯实物业服务和中心安全工作基础，激发全体员工的活力和动力，形成物业安全管理新格局，提升中心安全性和市民满意度。</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="line-height: 9px;max-height:2px;min-height: 1px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(62, 62, 62); box-sizing: border-box !important; word-wrap: break-word !important; ">
        <strong
            style="max-width: 100%; color: rgb(41, 148, 128); line-height: 9px;max-height:2px;min-height: 1px; box-sizing: border-box !important; word-wrap: break-word !important;"><em
                style="max-width: 100%; box-sizing: border-box !important; word-wrap: break-word !important;"><span
                    style="max-width: 100%; font-size: 24px; box-sizing: border-box !important; padding-left:4px;word-wrap: break-word !important;font-family: PingFangSC-Semibold,sans-serif;">1</span></em></strong>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="line-height: 9px;max-height:2px;min-height: 1px; max-width: 100%; white-space: pre-wrap; color: rgb(62, 62, 62); box-sizing: border-box !important; word-wrap: break-word !important; ">
        <br></p>
    <h2 label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin-top: -10px; font-family: PingfangSC-LIGHT,sans-serif; line-height: 9px;height:9px; color: white;border-radius: 10px;background:linear-gradient(to right,rgb(41, 148, 128) 20%,rgb(73, 200, 149) 10%) ;">
        &nbsp; &nbsp;</h2>
    <p
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(137, 137, 137); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <strong
            style="max-width: 100%; color: rgb(62, 62, 62); line-height: 9px;max-height:2px;min-height: 1px; box-sizing: border-box !important; word-wrap: break-word !important;"><span
                style="max-width: 100%; font-size: 20px; box-sizing: border-box !important; word-wrap: break-word !important;font-family: PingFangSC-Semibold,sans-serif;">
                <jncounttag></jncounttag>指导思想
            </span></strong></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">本系统建设是深入贯彻落实党的十九届四中、五中全会精神，坚持统筹发展和安全，推进国家治理体系和治理能力现代化。</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>坚持理顺关系、整合创新、重心下移、明确责任、转变作风、提高效能的原则，着力推进中心物业安全的网格化建设。进一步健全完善“纵向到底，横向到边、条块结合、协调联动”的工作体系，不断夯实物业服务和中心安全的工作基础，切实提高中心的服务效能和安全管理水平,不断提升市民朋友的满意度和安全感。</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="line-height: 9px;max-height:2px;min-height: 1px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(62, 62, 62); box-sizing: border-box !important; word-wrap: break-word !important; ">
        <strong
            style="max-width: 100%; color: rgb(41, 148, 128); line-height: 9px;max-height:2px;min-height: 1px; box-sizing: border-box !important; word-wrap: break-word !important;"><em
                style="max-width: 100%; box-sizing: border-box !important; word-wrap: break-word !important;"><span
                    style="max-width: 100%; font-size: 24px; box-sizing: border-box !important; padding-left:4px;word-wrap: break-word !important;font-family: PingFangSC-Semibold,sans-serif;">2</span></em></strong>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="line-height: 9px;max-height:2px;min-height: 1px; max-width: 100%; white-space: pre-wrap; color: rgb(62, 62, 62); box-sizing: border-box !important; word-wrap: break-word !important; ">
        <br></p>
    <h2 label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin-top: -10px; font-family: PingfangSC-LIGHT,sans-serif; line-height: 9px;height:9px; color: white;border-radius: 10px;background:linear-gradient(to right,rgb(41, 148, 128) 40%,rgb(73, 200, 149) 10%) ;">
        &nbsp; &nbsp;</h2>
    <p
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(137, 137, 137); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <strong
            style="max-width: 100%; color: rgb(62, 62, 62); line-height: 9px;max-height:2px;min-height: 1px; box-sizing: border-box !important; word-wrap: break-word !important;"><span
                style="max-width: 100%; font-size: 20px; box-sizing: border-box !important; word-wrap: break-word !important;font-family: PingFangSC-Semibold,sans-serif;">
                <jncounttag></jncounttag>网格化管理特色
            </span></strong></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="box-sizing: border-box;margin-left: 35px;margin-right: 35px;line-height: 40px;padding: 10px;color:#2b3835;padding-right: 10px;padding-left: 10px;text-align: center;box-sizing: border-box;font-size: 18px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span
            style="background:linear-gradient(to bottom, rgb(255, 255, 254) 60%,rgba(52, 191, 136, 0.49) 40%);">构建了完备的中心物业安全管理体制框架</span>
    </p>
    <section label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="width: 0px; height: 0px; clear: both;"><br></section>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">为建立健全责任制为核心，建立适合中心的物业安全管理体制，小组对中心的物业安全管理现状进行了仔细分享研究，明确了中心网格化管理体系的组织架构、工作内容和工作职责。</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="box-sizing: border-box;margin-left: 35px;margin-right: 35px;line-height: 40px;padding: 10px;color:#2b3835;padding-right: 10px;padding-left: 10px;text-align: center;box-sizing: border-box;font-size: 18px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span
            style="background:linear-gradient(to bottom, rgb(255, 255, 254) 60%,rgba(52, 191, 136, 0.49) 40%);">构建了“条”、“块”有机统筹的工作机制</span>
    </p>
    <section label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="width: 0px; height: 0px; clear: both;"><br></section>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">以区域网格为“块”，以专项工作组为“条”。一方面突出“块”的统筹作用，加强每个网格内部的统筹管理，把物业安全管理工作落细到网格内，能第一时间发现问题，第一时间在网格内解决；另一方面要强调“条”的专业功能，发挥物业安全部门各专项工作组的职能作用，提高解决和处置问题的能力。</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="box-sizing: border-box;margin-left: 35px;margin-right: 35px;line-height: 40px;padding: 10px;color:#2b3835;padding-right: 10px;padding-left: 10px;text-align: center;box-sizing: border-box;font-size: 18px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span
            style="background:linear-gradient(to bottom, rgb(255, 255, 254) 60%,rgba(52, 191, 136, 0.49) 40%);">形成多元参与的物业安全管理工作格局</span>
    </p>
    <section label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="width: 0px; height: 0px; clear: both;"><br></section>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">通过实行网格化管理，更好地动员各板块人员参与物业安全管理工作。全面动员基层党员、教师、各类员工，全面梳理物业安全工作，逐步将物业安全工作内容纳入网格职责，建立工作运行机制，推动网格化管理工作的落实。多种角色多元参与</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="box-sizing: border-box;margin-left: 35px;margin-right: 35px;line-height: 40px;padding: 10px;color:#2b3835;padding-right: 10px;padding-left: 10px;text-align: center;box-sizing: border-box;font-size: 18px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span
            style="background:linear-gradient(to bottom, rgb(255, 255, 254) 60%,rgba(52, 191, 136, 0.49) 40%);">分级管理快速响应</span>
    </p>
    <section label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="width: 0px; height: 0px; clear: both;"><br></section>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">根据分级管理理念，对各个网格的每项管理内容进行评估与排序，明确网格管理的工作重点并实施对工作重点的动态监测，同时配置相应的处置手段，落实每一项服务内容和应急处置事项。</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="line-height: 9px;max-height:2px;min-height: 1px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(62, 62, 62); box-sizing: border-box !important; word-wrap: break-word !important; ">
        <strong
            style="max-width: 100%; color: rgb(41, 148, 128); line-height: 9px;max-height:2px;min-height: 1px; box-sizing: border-box !important; word-wrap: break-word !important;"><em
                style="max-width: 100%; box-sizing: border-box !important; word-wrap: break-word !important;"><span
                    style="max-width: 100%; font-size: 24px; box-sizing: border-box !important; padding-left:4px;word-wrap: break-word !important;font-family: PingFangSC-Semibold,sans-serif;">3</span></em></strong>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="line-height: 9px;max-height:2px;min-height: 1px; max-width: 100%; white-space: pre-wrap; color: rgb(62, 62, 62); box-sizing: border-box !important; word-wrap: break-word !important; ">
        <br></p>
    <h2 label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin-top: -10px; font-family: PingfangSC-LIGHT,sans-serif; line-height: 9px;height:9px; color: white;border-radius: 10px;background:linear-gradient(to right,rgb(41, 148, 128) 60%,rgb(73, 200, 149) 10%) ;">
        &nbsp; &nbsp;</h2>
    <p
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(137, 137, 137); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <strong
            style="max-width: 100%; color: rgb(62, 62, 62); line-height: 9px;max-height:2px;min-height: 1px; box-sizing: border-box !important; word-wrap: break-word !important;"><span
                style="max-width: 100%; font-size: 20px; box-sizing: border-box !important; word-wrap: break-word !important;font-family: PingFangSC-Semibold,sans-serif;">
                <jncounttag></jncounttag>网格数字系统特色
            </span></strong></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="box-sizing: border-box;margin-left: 35px;margin-right: 35px;line-height: 40px;padding: 10px;color:#2b3835;padding-right: 10px;padding-left: 10px;text-align: center;box-sizing: border-box;font-size: 18px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span
            style="background:linear-gradient(to bottom, rgb(255, 255, 254) 60%,rgba(52, 191, 136, 0.49) 40%);">一套系统：“中心物业安全网格化数字系统”</span>
    </p>
    <section label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="width: 0px; height: 0px; clear: both;"><br></section>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">系统包含二维码入口及一套线上数字平台，二维码分布于中心不同楼层或部门，并与该楼层或部门的网格化信息相关联，通过二维码入口可直接进入相关的网格化数字系统页面。</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="box-sizing: border-box;margin-left: 35px;margin-right: 35px;line-height: 40px;padding: 10px;color:#2b3835;padding-right: 10px;padding-left: 10px;text-align: center;box-sizing: border-box;font-size: 18px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span
            style="background:linear-gradient(to bottom, rgb(255, 255, 254) 60%,rgba(52, 191, 136, 0.49) 40%);">两种终端：手机端和电脑端</span>
    </p>
    <section label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="width: 0px; height: 0px; clear: both;"><br></section>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">通过“扫一扫”的方式，在手机端能直接查看网格负责人、网格职责信息、联系人、最新通知等，上报事件和反馈工作信息；通过电脑端登录网页，能展示整个系统的统计信息，处理和跟踪上报事件，并发布和查看通知，编辑、管理和维护系统数据等。</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="box-sizing: border-box;margin-left: 35px;margin-right: 35px;line-height: 40px;padding: 10px;color:#2b3835;padding-right: 10px;padding-left: 10px;text-align: center;box-sizing: border-box;font-size: 18px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span
            style="background:linear-gradient(to bottom, rgb(255, 255, 254) 60%,rgba(52, 191, 136, 0.49) 40%);">三级权限：领导组权限、网格长权限、网格员权限</span>
    </p>
    <section label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="width: 0px; height: 0px; clear: both;"><br></section>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">领导组可查看整个网格化数字系统的所有信息，网格长能查看所管理网格的所有信息，网格员只能查看普通信息。</span>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="box-sizing: border-box;margin-left: 35px;margin-right: 35px;line-height: 40px;padding: 10px;color:#2b3835;padding-right: 10px;padding-left: 10px;text-align: center;box-sizing: border-box;font-size: 18px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span
            style="background:linear-gradient(to bottom, rgb(255, 255, 254) 60%,rgba(52, 191, 136, 0.49) 40%);">四大功能：网格信息可视化、评价反馈、数据统计、分级管理</span>
    </p>
    <section label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="width: 0px; height: 0px; clear: both;"><br></section>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <span
            style="max-width: 100%; line-height: 28px; box-sizing: border-box !important; word-wrap: break-word !important;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">系统的最基础功能是根据不同的空间位置，展示相应的网格信息，并在手机端提供评价和上报入口，用户能直接反馈相关信息。
        </span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="line-height: 9px;max-height:2px;min-height: 1px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(62, 62, 62); box-sizing: border-box !important; word-wrap: break-word !important; ">
        <strong
            style="max-width: 100%; color: rgb(41, 148, 128); line-height: 9px;max-height:2px;min-height: 1px; box-sizing: border-box !important; word-wrap: break-word !important;"><em
                style="max-width: 100%; box-sizing: border-box !important; word-wrap: break-word !important;"><span
                    style="max-width: 100%; font-size: 24px; box-sizing: border-box !important; padding-left:4px;word-wrap: break-word !important;font-family: PingFangSC-Semibold,sans-serif;">4</span></em></strong>
    </p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="line-height: 9px;max-height:2px;min-height: 1px; max-width: 100%; white-space: pre-wrap; color: rgb(62, 62, 62); box-sizing: border-box !important; word-wrap: break-word !important; ">
        <br></p>
    <h2 label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin-top: -10px; font-family: PingfangSC-LIGHT,sans-serif; line-height: 9px;height:9px; color: white;border-radius: 10px;background:linear-gradient(to right,rgb(41, 148, 128) 80%,rgb(73, 200, 149) 10%) ;">
        &nbsp; &nbsp;</h2>
    <p
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(137, 137, 137); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <strong
            style="max-width: 100%; color: rgb(62, 62, 62); line-height: 9px;max-height:2px;min-height: 1px; box-sizing: border-box !important; word-wrap: break-word !important;"><span
                style="max-width: 100%; font-size: 20px; box-sizing: border-box !important; word-wrap: break-word !important;font-family: PingFangSC-Semibold,sans-serif;">
                <jncounttag></jncounttag>系统使用说明
            </span></strong></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px; max-width: 100%; min-height: 1em; white-space: pre-wrap; color: rgb(43, 43, 43); text-align: justify; line-height: 1.5; box-sizing: border-box !important; word-wrap: break-word !important; ">
        <br></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>微信扫码进入网格信息页。</span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>快速查看及联系对应网格负责人。</span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>查看最新通知及发布通知。</span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>提交网格建议及反馈信息。</span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>跟踪处理反馈信息。</span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>查看统计信息：系统统计、网格统计、反馈统计、人员统计。</span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>用户权限管理。</span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>网格创建及网格员职责划分。</span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>工作内容推送。</span></p>
    <p label="Converted by KNB Formatter from Jason Ng https://knb.im/mp"
        style="margin: 20px 10px;max-width: 100%;min-height: 1em;color: rgb(43, 43, 43);text-align: justify;line-height: 28px;box-sizing: border-box !important;word-wrap: break-word !important;background-color: rgb(238, 253, 247);border-left: 10px solid #49c895;padding: 16px;border-right: 2px solid #6bdeb0;letter-spacing:1px;font-size:15px;font-family: PingfangSC-LIGHT,sans-serif;">
        <span>查看网格系统介绍。</span></p>    
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  data(){
      return {
        
      }
    },
  components: {

  },

  created:function (){
        
  },
}
</script>

<style scoped>
.introduction {
    cursor: text;
    *cursor: auto
}

img,
input,
textarea {
    cursor: default
}

.cke_editable {
    cursor: text
}

.cke_editable img,
.cke_editable input,
.cke_editable textarea {
    cursor: default
}

img.cke_flash {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/flash/images/placeholder.png?t=G2VC);
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #a9a9a9;
    width: 80px;
    height: 80px;
}

.cke_editable form {
    border: 1px dotted #FF0000;
    padding: 2px;
}

img.cke_hidden {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/forms/images/hiddenfield.gif?t=G2VC);
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #a9a9a9;
    width: 16px !important;
    height: 16px !important;
}

img.cke_iframe {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/iframe/images/placeholder.png?t=G2VC);
    background-position: center center;
    background-repeat: no-repeat;
    border: 1px solid #a9a9a9;
    width: 80px;
    height: 80px;
}

.cke_contents_ltr a.cke_anchor,
.cke_contents_ltr a.cke_anchor_empty,
.cke_editable.cke_contents_ltr a[name],
.cke_editable.cke_contents_ltr a[data-cke-saved-name] {
    background: url(https://cn404.com/ckeditor_for_wx/plugins/link/images/anchor.png?t=G2VC) no-repeat left center;
    border: 1px dotted #00f;
    background-size: 16px;
    padding-left: 18px;
    cursor: auto;
}

.cke_contents_ltr img.cke_anchor {
    background: url(https://cn404.com/ckeditor_for_wx/plugins/link/images/anchor.png?t=G2VC) no-repeat left center;
    border: 1px dotted #00f;
    background-size: 16px;
    width: 16px;
    min-height: 15px;
    height: 1.15em;
    vertical-align: text-bottom;
}

.cke_contents_rtl a.cke_anchor,
.cke_contents_rtl a.cke_anchor_empty,
.cke_editable.cke_contents_rtl a[name],
.cke_editable.cke_contents_rtl a[data-cke-saved-name] {
    background: url(https://cn404.com/ckeditor_for_wx/plugins/link/images/anchor.png?t=G2VC) no-repeat right center;
    border: 1px dotted #00f;
    background-size: 16px;
    padding-right: 18px;
    cursor: auto;
}

.cke_contents_rtl img.cke_anchor {
    background: url(https://cn404.com/ckeditor_for_wx/plugins/link/images/anchor.png?t=G2VC) no-repeat right center;
    border: 1px dotted #00f;
    background-size: 16px;
    width: 16px;
    min-height: 15px;
    height: 1.15em;
    vertical-align: text-bottom;
}

div.cke_pagebreak {
    background: url(https://cn404.com/ckeditor_for_wx/plugins/pagebreak/images/pagebreak.gif?t=G2VC) no-repeat center center !important;
    clear: both !important;
    width: 100% !important;
    border-top: #999 1px dotted !important;
    border-bottom: #999 1px dotted !important;
    padding: 0 !important;
    height: 7px !important;
    cursor: default !important;
}

.cke_show_blocks h6:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks h5:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks h4:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks h3:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks h2:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks h1:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks blockquote:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks address:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks pre:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks div:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks p:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-repeat: no-repeat;
    border: 1px dotted gray;
    padding-top: 8px
}

.cke_show_blocks h6:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_h6.png?t=G2VC)
}

.cke_show_blocks h5:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_h5.png?t=G2VC)
}

.cke_show_blocks h4:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_h4.png?t=G2VC)
}

.cke_show_blocks h3:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_h3.png?t=G2VC)
}

.cke_show_blocks h2:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_h2.png?t=G2VC)
}

.cke_show_blocks h1:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_h1.png?t=G2VC)
}

.cke_show_blocks blockquote:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_blockquote.png?t=G2VC)
}

.cke_show_blocks address:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_address.png?t=G2VC)
}

.cke_show_blocks pre:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_pre.png?t=G2VC)
}

.cke_show_blocks div:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_div.png?t=G2VC)
}

.cke_show_blocks p:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-image: url(https://cn404.com/ckeditor_for_wx/plugins/showblocks/images/block_p.png?t=G2VC)
}

.cke_show_blocks.cke_contents_ltr h6:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr h5:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr h4:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr h3:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr h2:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr h1:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr blockquote:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr address:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr pre:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr div:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_ltr p:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-position: top left;
    padding-left: 8px
}

.cke_show_blocks.cke_contents_rtl h6:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl h5:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl h4:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl h3:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl h2:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl h1:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl blockquote:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl address:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl pre:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl div:not([contenteditable=false]):not(.cke_show_blocks_off),
.cke_show_blocks.cke_contents_rtl p:not([contenteditable=false]):not(.cke_show_blocks_off) {
    background-position: top right;
    padding-right: 8px
}

.cke_show_borders table.cke_show_border,
.cke_show_borders table.cke_show_border>tr>td,
.cke_show_borders table.cke_show_border>tr>th,
.cke_show_borders table.cke_show_border>tbody>tr>td,
.cke_show_borders table.cke_show_border>tbody>tr>th,
.cke_show_borders table.cke_show_border>thead>tr>td,
.cke_show_borders table.cke_show_border>thead>tr>th,
.cke_show_borders table.cke_show_border>tfoot>tr>td,
.cke_show_borders table.cke_show_border>tfoot>tr>th {
    border: #d3d3d3 1px dotted
}

/* contents.css */
.introduction{
    /* Font */
    font-family: sans-serif, Arial, Verdana, "Trebuchet MS";
    font-size: 12px;

    /* Text color */
    color: #333;

    /* Remove the background color to make it transparent */
    background-color: #fff;
}
.cke_editable
{
	font-size: 13px;
	line-height: 1.6;

	/* Fix for missing scrollbars with RTL texts. (#10488) */
	word-wrap: break-word;
}

blockquote
{
	font-style: italic;
	font-family: Georgia, Times, "Times New Roman", serif;
	padding: 2px 0;
	border-style: solid;
	border-color: #ccc;
	border-width: 0;
}

.cke_contents_ltr blockquote
{
	padding-left: 20px;
	padding-right: 8px;
	border-left-width: 5px;
}

.cke_contents_rtl blockquote
{
	padding-left: 8px;
	padding-right: 20px;
	border-right-width: 5px;
}

a
{
	color: #0782C1;
}

ol,ul,dl
{
	/* IE7: reset rtl list margin. (#7334) */
	*margin-right: 0px;
	/* preserved spaces for list items with text direction other than the list. (#6249,#8049)*/
	padding: 0 40px;
}

h1,h2,h3,h4,h5,h6
{
	font-weight: normal;
	line-height: 1.2;
}

hr
{
	border: 0px;
	border-top: 1px solid #ccc;
}

img.right
{
	border: 1px solid #ccc;
	float: right;
	margin-left: 15px;
	padding: 5px;
}

img.left
{
	border: 1px solid #ccc;
	float: left;
	margin-right: 15px;
	padding: 5px;
}

pre
{
	white-space: pre-wrap; /* CSS 2.1 */
	word-wrap: break-word; /* IE7 */
	-moz-tab-size: 4;
	tab-size: 4;
}

.marker
{
	background-color: Yellow;
}

span[lang]
{
	font-style: italic;
}

figure
{
	text-align: center;
	border: solid 1px #ccc;
	border-radius: 2px;
	background: rgba(0,0,0,0.05);
	padding: 10px;
	margin: 10px 20px;
	display: inline-block;
}

figure > figcaption
{
	text-align: center;
	display: block; /* For IE8 */
}

a > img {
    padding: 1px;
    margin: 1px;
    border: none;
    outline: 1px solid #0782C1;
}
h2{
    height: -webkit-fill-available;
}
</style>
