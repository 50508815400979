<template>
  <div class="noticedetail font-all">
    <div class="note-title">{{this.notes.title}}</div>
    <!-- <div class="note-title">的哈肯定会看到电话卡的话卡的ad就撒大家啊快点交阿萨德sad静安寺快点交sak</div> -->

    <van-divider class="van-divider" />

    <div class="note-text">
        <p v-for="(pp, index1) in this.notes.content" :key="'note-'+index1">{{pp}}</p>
    </div>

    <van-divider class="van-divider" />

    <div class="note-info">
        <span class="note-info-dapartment">{{this.notes.publisher}}</span>
        <van-icon name="clock-o" color="#999999"/>
        <span class="note-info-time">{{this.notes.created_at.slice(0,10)}}</span>
    </div>

    <div class="button-share">
        <van-button type="info" v-on:click="showOverlay = true">分享</van-button>
    </div>

    <van-overlay :show="showOverlay" @click="showOverlay = false">
        <div class="wrapper">
            <div class="overlay-image">
                <img src="../assets/noticedetail-b.png" alt="row">
            </div>
            <div class="overlay-text">
                点击右上角，发送给朋友、分享到朋友圈
            </div>
            <div class="overlay-text-know">
                <div>知道了</div>
            </div>
        </div>
    </van-overlay>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

export default {
  name: 'noticedetail',
  data(){
      return {
          noticeId:'',
          gridId:'',
          notes:'',
          showOverlay: false
      }
    },
  components: {

  },

// 组件创建调用
  created:function (){
    //   let gridid = this.$route.params.gridid;
    //   let noticeid = this.$route.params.noticeid;
    //   console.log("noticedetail网格id是：",gridid);
    //   console.log("noticedetail通知index是：",noticeid);
    //   this.noticeId=noticeid;
    //   this.gridId=gridid;

    //   //读取
    //   let str = sessionStorage.note;
    
    //   //重新转换为对象
    //   let note_ = JSON.parse(str);
    //   note_.content = note_.content.split(/[(\r\n)\r\n]+/);
    //   this.notes = note_;
    let noticeid = this.$route.params.noticeid;
    console.log("渲染noticedetail页面获取的noticeid是：",noticeid)
    axios.get('/api/wx/notification',{
            params: { 
            notification_id: noticeid
        }}).then((response) => {
            console.log("返回的通知信息是：",response.data);
            console.log("返回的通知信息是：",response.data.data.list);
            this.notes = response.data.data.list[0];
            this.notes.content = this.notes.content.split(/[(\r\n)\r\n]+/);
            this.share();
      })
    //   this.fetchData();
  },

//   定义方法
  methods:{

    share(){
        //自定义分享内容
        //需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({ 
            title: this.notes.title, // 分享标题
            desc: this.notes.content[0], // 分享描述
            link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: '../assets/noticedetail-a.png', // 分享图标
            success: function () {
            // 设置成功
            }
        });
        wx.updateTimelineShareData({ 
            title: this.notes.title, // 分享标题
            link: this.notes.content[0], // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: '../assets/noticedetail-a.png', // 分享图标
            success: function () {
            // 设置成功
            }
        });
        wx.onMenuShareWeibo({
            title: this.notes.title, // 分享标题
            desc: this.notes.content[0], // 分享描述
            link: location.href, // 分享链接(当前页面URL)，与后端参与签名的字段一致
            imgUrl: '../assets/noticedetail-a.png', // 分享图标，建议 300*300px
            success: function () {
                // 用户确认分享后执行的回调函数
            },
            cancel: function () {
                // 用户取消分享后执行的回调函数
            }
        });
        wx.onMenuShareTimeline({
            title: this.notes.title, // 分享标题
            link: location.href, // 分享链接(当前页面URL)，与后端参与签名的字段一致
            imgUrl: '../assets/noticedetail-a.png', // 分享图标，建议 300*300px
            success: function () {
                // 用户确认分享后执行的回调函数
            },
            cancel: function () {
                // 用户取消分享后执行的回调函数
            }
        });
        // 页面加载后设置微信分享给朋友的内容
        wx.onMenuShareAppMessage({
            title: this.notes.title, // 分享标题
            desc: this.notes.content[0], // 分享描述
            link: location.href, // 分享链接(当前页面URL)，与后端参与签名的字段一致
            imgUrl: '../assets/noticedetail-a.png', // 分享图标，建议 300*300px
            type: '', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
                // 用户确认分享后执行的回调函数
            },
            cancel: function () {
                // 用户取消分享后执行的回调函数
            }
        });
        // 页面加载后设置微信分享到QQ的内容
        wx.onMenuShareQQ({
            title: this.notes.title, // 分享标题
            desc: this.notes.content[0], // 分享描述
            link: location.href, // 分享链接(当前页面URL)，与后端参与签名的字段一致
            imgUrl: '../assets/noticedetail-a.png', // 分享图标，建议 300*300px
            success: function () {
                // 用户确认分享后执行的回调函数
            },
            cancel: function () {
                // 用户取消分享后执行的回调函数
            }
        });
        // 页面加载后设置微信分享到QQ空间的内容
        wx.onMenuShareWeibo({
            title: this.notes.title, // 分享标题
            desc: this.notes.content[0], // 分享描述
            link: location.href, // 分享链接(当前页面URL)，与后端参与签名的字段一致
            imgUrl: '../assets/noticedetail-a.png', // 分享图标，建议 300*300px
            success: function () {
                // 用户确认分享后执行的回调函数
            },
            cancel: function () {
                // 用户取消分享后执行的回调函数
            }
        });
    },

    //   获取网络数据
    fetchData () {

        // 请求网格人员信息
        axios.get("/api/wx/notification",{
            params:{
                grid_id:this.gridId
            }
        })
        .then((response) => {
        if(response.data.code===0){
            console.log("noticedetail返回的通知信息是：",response.data)
            let data=response.data.data;
            this.notes=data.list;

            let xxx=this.notes[this.noticeId].content.split(/[(\r\n)\r\n]+/);
            xxx.forEach((item,index)=>{
                if(!item){
                    xxx.splice(index,1);//删除空项
                }
            })
            this.notes[this.noticeId].content=xxx;
            console.log('noticedetail分割后的通知是:',xxx);
        }
        else{
            alert("noticedetail后台返回错误："+response.data.msg);
        }
        })
        .catch(function (error) {
        console.log("noticedetail错误信息：",error);
        });

    },
  }
}
</script>

<style scoped>
/* 整体样式 */
.font-all{
    font-size: 14px;
}

/* 通知标题样式 */
.note-title{
    font-weight: bold;
    text-align: center;
    font-size: 18px;
    margin: 20px auto;
    width: 75%;
}

/* 通知文本段落样式 */
.note-text{
    font-size: 16px;
    margin: 4px 2em;
    text-indent: 2em;
    line-height: 150%;
    color: #666666;
    text-align: justify;
}

/* 分割线的样式 */
van-divider{
    width: auto;
    margin: 0 10px;
    background-color:#666;
}

/* 底部展示通知信息部门时间的样式 */
.note-info{
    align-items: right;
    text-align: right;
    margin: 4px 2em 10px 2em;
}
.note-info .note-info-dapartment{
    margin: 0 5px 0 0;
}
.note-info .note-info-time{
    margin: 0 0 0 2px;
}

/* 分享按钮的样式 */
.button-share{
    margin: 20px 20px;
    width: auto;
}
.van-button--info {
    width: -webkit-fill-available;
  border-radius: 10px;
  background-color: #0098ff;
}

/* 遮罩层 */
.wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.overlay-image{
    text-align: center;
}
.overlay-image img{
    width: 50%;
    height: auto;
    margin: 30px 0;
    padding: 0 20px;
}

.overlay-text{
    text-align: center;
    font-size: 20px;
    color: white;
    margin: 30px 10px;
}
.overlay-text-know{
    text-align: center;
}
.overlay-text-know div{
    border:1px solid #FFF;
    border-radius: 5px;
    font-size: 20px;
    color: white;
    width: fit-content;
    display: inline-block;
    margin: 30px 10px;
    padding: 2px 15px;
}
</style>