<template>
  <div class="feedback font-all">
      <!-- 输入反馈信息文本框 -->
      <div>
          <van-field
            v-model="problemValue"
            @input="problemOnInput"
            rows="6"
            type="textarea"
            maxlength="1000"
            placeholder="说说你的问题(不少于10字)"
            show-word-limit
            clearable
            />
      </div>

      <!-- 图片上传 -->
      <div class="image-upload">
          <div class="image-text-desc">
              <div class="text-desc-origin">请提供相关问题截图或照片</div>
              <div>{{imageFileList.length}}/3</div>
          </div>
          <van-uploader 
          v-model="imageFileList" 
          multiple
          :max-count="3"
          :before-read="beforeReadImage"
          :after-read="afterReadImage" />
      </div>

      <!-- 提交按钮 -->
      <div class="submit-button">
          <van-button type="default" v-on:click="clickSubmitButton">提交</van-button>
      </div>
      
      <!-- 页面说明文字 -->
      <div class="feedback-note">
          <p>注：您提交的问题会进行统一处理，反馈结果将通过推送的方式及时公示；为保证服务质量，每人每天限制提交三次。</p>
      </div>

      <!-- 弹窗1 -->
      <!-- <van-dialog v-model="showSubmitDialog"> -->
      <!-- <van-dialog > -->
          <!-- <img v-bind:src="feedbackDialogInfo.src" /> -->
              <!-- <img :src="feedbackDialogInfo.src" :alt="feedbackDialogInfo.src"/> -->
              <!-- <div class="dialog-title">{{feedbackDialogInfo.title}}</div> -->
              <!-- <div class="dialog-message">{{feedbackDialogInfo.message}}</div> -->
          <!-- </div> -->
      <!-- </van-dialog> -->

      <!-- 弹窗2 -->
      <van-popup 
        v-model="showSubmitDialog" 
        get-container="#app" 
        round 
        :close-on-click-overlay="false"
        @close="closePopup">
          <div class="van-dialog-content">
              <img :src="feedbackDialogInfo.src" :alt="feedbackDialogInfo.src"/>
              <div class="dialog-title">{{feedbackDialogInfo.title}}</div>
              <div class="dialog-message">{{feedbackDialogInfo.message}}</div>
              <van-divider />
              <div class="dialog-close" v-on:click="clickCloseDialog">
                  知道了
              </div>
          </div>
      </van-popup>
  </div>
</template>

<script>
// @ is an alias to /src
import Compressor from 'compressorjs';
import axios from 'axios';

export default {
  name: 'Feedback',
  data(){
      return {
        gridId:"",
        problemValue:'',
        showSubmitDialog:false,
        feedbackDialogInfo:{
            src:require('../assets/feedback-success.png'),
            title:'提交成功',
            message:''
         },
         feedbackDialogSuccess:0,

        // 图片对象
        imageFileList:[],
      }
    },

    created:function (){
      let gridid = this.$route.params.gridid;
      console.log("noticedetail网格id是：",gridid);
    //   console.log("noticedetail通知index是：",noticeid);
    //   this.noticeId=noticeid;
      this.gridId=gridid;
  },

  // 需要的子组件
  components: {

  },

  //   定义方法
  methods:{
    
    // 完成关闭最终弹窗之后的事件触发
    closePopup(event){
        console.log("feedback页面在1秒之后已经自动关闭了弹窗层！")
    },

    // 输入信息时获取信息
    problemOnInput(event){
        console.log("feedback页面输入变化了：",1)
    },

    // // 上传图片
    // // 图片上传
    // uploadImg(file) {
    //   console.log(file);  //控制台可以看见图片信息
    //   const param = new FormData();
    //   param.append("file", file.file);
    //   param.append("token", localStorage.getItem("token"));
    //   this.$post("", param, {
    //     headers: { "Content-Type": "multipart/form-data" }
    //   }).then(res => {
    //     this.oneurl = ''; 
    //   });
    // },

    clickCloseDialog(event){
        this.showSubmitDialog = false;
        if(this.feedbackDialogSuccess === 1){
            this.$router.go(-1);
        }
    },

    // 点击提交按钮
    clickSubmitButton(event){
        let l1=this.problemValue.length;
        let l2=this.imageFileList.length; //请求当前提交次数
        if(l1>=10 && l2===0)
        {
            let param = {
                    "grid_id":this.gridId,
                    "content": this.problemValue
                };
            axios.post('/api/wx/feedback',param)
                .then((response) => {
                    if(response.data.code===0)
                    {
                        console.log("feedback上传反馈信息的返回值是：",response.data)
                        // 设置弹窗
                        this.showSubmitDialog=true;
                        this.feedbackDialogInfo.src=require('../assets/feedback-success.png');
                        this.feedbackDialogInfo.title='提交成功';
                        this.feedbackDialogInfo.message='感谢您对网格化系统提出宝贵的反馈';
                        this.feedbackDialogSuccess = 1;
                    }
                    else if(response.data.code===3005){
                        this.showSubmitDialog=true;
                        this.feedbackDialogInfo.src=require('../assets/feedback-fail.png');
                        this.feedbackDialogInfo.title='提交失败';
                        this.feedbackDialogInfo.message='您已超过当日提交次数限制';
                        this.feedbackDialogSuccess = 1;
                        // 设置弹窗关闭的除法时间，自动关闭的时间是1.2s
                        // setTimeout(() => {
                        // this.showSubmitDialog = false;
                        // if(l1>=10 && l2<=3){
                        //     this.$router.go(-1)
                        // }
                        // }, 1200);
                    }
                })
        }
        else if(l1>=10 && l2<=3 && l2>0){
            let param = new FormData();
            this.imageFileList.map((item)=>{
                //files是后台参数name字段对应值
                param.append("images", item.file, item.file.name);
            })
            console.log('feedback上传图片的param值是：',param.getAll('images'))
            axios.post('/api/wx/upload',param)
            .then((response) => {
            if(response.data.code===0){
                console.log("feedback上传图片的信息的返回值是：",response.data)

                let param = {
                    "grid_id":this.gridId,
                    "content": this.problemValue,
                    "imgs": response.data.data
                };
                // this.imageFileList.map((item)=>{
                //     //files是后台参数name字段对应值
                //     param.append("images", item.file, item.file.name);
                // })
                console.log(this.gridId)
                console.log(this.problemValue)
                console.log(response.data.data)
                axios.post('/api/wx/feedback',param)
                .then((response) => {
                    if(response.data.code===0)
                    {
                        console.log("feedback上传反馈信息的返回值是：",response.data)
                    // 设置弹窗
                    this.showSubmitDialog=true;
                    this.feedbackDialogInfo.src=require('../assets/feedback-success.png');
                    this.feedbackDialogInfo.title='提交成功';
                    this.feedbackDialogInfo.message='感谢您对网格化系统提出宝贵的反馈';
                    this.feedbackDialogSuccess = 1;
                    // 设置弹窗关闭的除法时间，自动关闭的时间是1.2s
                    // setTimeout(() => {
                    // this.showSubmitDialog = false;
                    // if(l1>=10 && l2<3){
                    //     this.$router.go(-1)
                    // }
                    // }, 1200);
                    }
                    else if(response.data.code===3005){
                        this.showSubmitDialog=true;
                        this.feedbackDialogInfo.src=require('../assets/feedback-fail.png');
                        this.feedbackDialogInfo.title='提交失败';
                        this.feedbackDialogInfo.message='您已超过当日提交次数限制';
                        this.feedbackDialogSuccess = 1;
                        // 设置弹窗关闭的除法时间，自动关闭的时间是1.2s
                        // setTimeout(() => {
                        // this.showSubmitDialog = false;
                        // if(l1>=10 && l2<=3){
                        //     this.$router.go(-1)
                        // }
                        // }, 1200);
                    }
                })
            }
            else{
                alert("feedback上传图片返回错误："+response.data.msg);
            }
            })
            // /api/wx/feedback
            // .catch(function (error) {
            // console.log("feedback上传图片错误信息：",error);
            // });
        }
        else if(l1<10){
            this.showSubmitDialog=true;
            this.feedbackDialogInfo.src=require('../assets/feedback-fail.png');
            this.feedbackDialogInfo.title='提交失败';
            this.feedbackDialogInfo.message='请描述问题详情';
            this.feedbackDialogSuccess = 0;
            // 设置弹窗关闭的除法时间，自动关闭的时间是1.2s
            // setTimeout(() => {
            // this.showSubmitDialog = false;
            // if(l1>=10 && l2<=3){
            //     this.$router.go(-1)
            // }
            // }, 1200);
        }
        // else{
        //     alert("请上传至少一张图片");
        // }
        // if(this.showSubmitDialog===true){
        //     // 设置弹窗关闭的除法时间，自动关闭的时间是1.2s
        //     setTimeout(() => {
        //     this.showSubmitDialog = false;
        //     if(l1>=10 && l2<3){
        //         this.$router.go(-1)
        //     }
        //     }, 1200);
        // }
    },

    // 在阅读上传图片前做一些检查和缩放工作
    beforeReadImage(file){
        console.log("feedback读取了图片")
        return new Promise((resolve)=>{
            // compressorjs 默认开启 checkOrientation 选项
            // 会将图片修正为正确方向
            new Compressor(file, {
                quality: 0.6,
                width: 1000,
                convertSize:2000000,
                success: resolve,
                // success(result) {
                //     resolve
                //     alert('chengg')
                // },
                error(err) {
                    console.log(err.message);
                },
        });
        });
    },

    // 图片读取之后
    afterReadImage(file) {
      // 此时可以自行将文件上传至服务器
      console.log("feedback的图片信息是：",file);
    //   this.imageFileList.push(file.content);
    },
  },
}
</script>

<style scoped>
/* 整个组件的字体样式 */
.font-all {
  font-size: 14px;
}

/* 输入框样式 */
.van-cell{
    margin: 10px;
    width: auto;
    /* height: ; */
    border-radius: 10px;
}

/* 图片上传文字描述 */
.image-text-desc{
    color: #999999;
    display: flex;
    flex-direction: row;
    padding: 0 0 10px 0;
}
.image-text-desc .text-desc-origin{
    flex: 1;
}
/* 整个div */
.image-upload{
    background-color: #fff;
    margin: 10px;
    border-radius: 10px;
    padding: 10px;
}
/* vant上传组件 */
.van-uploader /deep/ .van-uploader__upload{
    border: 1px dashed #ccc;
    border-radius: 10px;
}

/* 提交按钮 */
.submit-button{
    width: 100%;
    margin: 10px 0;
    text-align: center;
}
.submit-button .van-button{
    width: -webkit-fill-available;
    border-radius: 25px;
    margin: 0 10px;
    background-color: #0098FF;
    color: white;
}

/* 页面说明文本样式 */
.feedback-note{
    color: #999999;
    width: -webkit-fill-available;
    margin: 40px 10px 0 10px;
}

/* 弹出层样式 */
.van-dialog-content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0 40px 0;
}
.van-dialog-content img{
    width: 256px;
    height: 126px;
    margin: 20px 20px;
}
.van-dialog-content .dialog-title{
    font-size: 25px;
    color: #555555;
    font-weight:bold;
    margin: 20px 0 20px 0;
}
.van-dialog-content .dialog-message{
    color: #999999;
    font-size: 16px;
}
.dialog-close{
    font-size: 20px;
    color: red;
    /* padding-top: 5px;
    margin-top: 10px; */
}
</style>
