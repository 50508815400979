<template>
    <div class="staticinfo font-all">
        <!-- 标题部分 -->
        <div class="title-top">
            <img src="../assets/home-title-icon.png" alt="home-title-icon.png"/>
            <div>{{buildingName}}</div>
        </div>

        <div class="person-card-info">
            <div class="person-card-info-name">{{department}}网格</div>
            <div class="person-card-info-detail">
                <img src="../assets/staticinfo-divider.png" alt="gridperson-divider.png">
                <div class="person-card-info-detail-3">
                    <div>统计内容：网格数字化信息系统</div>
                    <div>截止日期：{{latestDayFeedback}}</div>
                </div>
            </div>
        </div>

        <!--1 反馈信息统计折线图，主要是 -->
        <div class="feedback-static-chart card">
            <div class="feedback-static-chart-title title">反馈信息统计</div>
            <div class="chart-info">数据类型：每日反馈信息数量</div>
            <div class="chart-info">统计日期：近7日</div>
            <div class="chart-all" id="chart-feedback"></div>
        </div>

        <!--2  -->
        <div class="card">
            <div class="title">最新反馈信息</div>
            <div class="note-info-bottom" v-for="(item, index) in feedbackInfoShow" :key="'feedback-'+index">
                <div class="note-info-bottom-order">{{index+1}}</div>
                <div class="note-info-bottom-text">
                    <div class="note-info-bottom-text1">{{item.content}}</div>
                    <div class="note-info-bottom-text234">
                        <span class="note-info-bottom-text2">{{item.comment}}</span>
                        <!-- <span class="note-info-bottom-text3">{{item.publisher}}</span> -->
                        <van-icon name="clock-o" color="#999999"/>
                        <span class="note-info-bottom-text4">{{item.created_at.slice(0,10)}}</span>
                    </div>
                </div>
            </div>
            <div v-if ="feedbackInfo.length>3&&feedbackInfo.length>0" @click = "showFeedbackInfoMore" class="note-info-more">{{moreFeedbackInfoFlag?"更多>>":"收起"}}</div>
            <div v-if ="feedbackInfo.length===0" class="info-none">无</div>
        </div>

        <!--3  -->
        <div class="card">
            <div class="title">网格</div>
            <div class="all-grid" v-for="(item, index) in allGridsShow" :key="'grids-'+index" @click="clickToNewGrid(item.id)">{{item.name}}网格</div>
            <div v-if ="allGrids.length>5&&allGrids.length>0" @click = "showGridsMore" class="note-info-more">{{moreGridsFlag?"更多>>":"收起"}}</div>
            <div v-if ="allGrids.length===0" class="info-none">无</div>
        </div>

        <!--4  -->
        <div class="card">
            <div class="title">通知列表</div>
            <div class="note-info-bottom" v-for="(item, index) in notesShow" :key="'note-'+index">
                <div class="note-info-bottom-order">{{index+1}}</div>
                <div class="note-info-bottom-text">
                    <div class="note-info-bottom-text1">{{item.title}}</div>
                    <div class="note-info-bottom-text234">
                        <span class="note-info-bottom-text2" v-on:click="clickNote(index)">查看详情</span>
                        <span class="note-info-bottom-text3">{{item.publisher}}</span>
                        <van-icon name="clock-o" color="#999999"/>
                        <span class="note-info-bottom-text4">{{item.created_at.slice(0,10)}}</span>
                    </div>
                </div>
            </div>
            <div v-if ="notes.length>3&&notes.length>0" @click = "showNotesMore" class="note-info-more">{{moreNotesFlag?"更多>>":"收起"}}</div>
            <div v-if ="notes.length===0" class="info-none">无</div>
        </div >

        <!--5  -->
        <div class="card">
            <div class="title">网格信息系统每日访问人次</div>
            <div class="chart-info">数据类型：每日访问人次</div>
            <div class="chart-info">统计日期：近7日</div>
            <div class="chart-all" id="chart-visit"></div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'

export default {
  name: 'staticinfo',

  data(){
      return {
        //   网格反馈截止日期
        latestDayFeedback:"",

        // 人员统计截止日期
        latestDayVisit:"",


          gridId:"",
          buildingName:"",
          department:"",

          allGrids:[
            //   "xxxxxxxxxxxxxxxxx网格",
            //   "xxxxxxxxxxxxxxxxx网格",
            //   "xxxxxxxxxxxxxxxxx网格",
            //   "xxxxxxxxxxxxxxxxx网格",
            //   "xxxxxxxxxxxxxxxxx网格",
            //   "xxxxxxxxxxxxxxxxx网格",
            //   "xxxxxxxxxxxxxxxxx网格",
          ],
          allGridsShow:[
          ],
          moreGridsFlag:true,

          feedbackInfo:[
            //   {
            //       title:"服务台需要增加消毒液等物质",
            //       status: "处理中",
            //       created_at:"2021-05-26T14:33:23+08:00",
            //       content:"xxxxxxxx"
            //   },
            //   {
            //       title:"服务台需要增加消毒液等物质",
            //       status: "处理中",
            //       created_at:"2021-05-26T14:33:23+08:00",
            //       content:"xxxxxxxx"
            //   },
            //   {
            //       title:"服务台需要增加消毒液等物质",
            //       status: "处理中",
            //       created_at:"2021-05-26T14:33:23+08:00",
            //       content:"xxxxxxxx"
            //   },
            //   {
            //       title:"服务台需要增加消毒液等物质",
            //       status: "处理中",
            //       created_at:"2021-05-26T14:33:23+08:00",
            //       content:"xxxxxxxx"
            //   },
            //   {
            //       title:"服务台需要增加消毒液等物质",
            //       status: "处理中",
            //       created_at:"2021-05-26T14:33:23+08:00",
            //       content:"xxxxxxxx"
            //   },
            //   {
            //       title:"服务台需要增加消毒液等物质",
            //       status: "处理中",
            //       created_at:"2021-05-26T14:33:23+08:00",
            //       content:"xxxxxxxx"
            //   },
          ],
          feedbackInfoShow:[
              
          ],
          moreFeedbackInfoFlag:true,


          notes:[
            //   {
            //       title:"关于做好大风黄色预警天气应对工作的通知",
            //       publisher:"物业安全部",
            //       created_at:"2021-05-26T14:33:23+08:00",
            //       content:"xxxxxxxx"
            //   },
            //   {
            //       title:"关于做好大风黄色预警天气应对工作的通知",
            //       publisher:"物业安全部",
            //       created_at:"2021-05-26T14:33:23+08:00",
            //       content:"xxxxxxxx"
            //   }
          ],
          notesShow:[
              
          ],
          moreNotesFlag:true,
      }
    },

  //   定义子组件
  components: {

  },

  // 组件创建调用
  created:function (){
    let gridid = this.$route.params.gridid;
    let buildingname = this.$route.query.buildingname;
    let department_=this.$route.query.department
    console.log("staticinfo的网格id是：",gridid);
    console.log("staticinfo的楼的名称是：",buildingname);
    console.log("staticinfo的楼的部门是：",department_);
    this.gridId=gridid;
    this.buildingName = buildingname;
    this.department = department_;

    //   初始化展示组件
    // this.notesShow=this.notes.slice(0,3);
    // this.allGridsShow=this.allGrids.slice(0,5);
    // this.feedbackInfoShow=this.feedbackInfo.slice(0,3);
  },

  mounted(){
    // 访问网络请求
    //   访问网格反馈统计数量
    axios.get('/api/admin/statistic/feedback',{
        params: { 
          grid_id:this.gridId
      }})
      .then((response) => {
        if(response.data.code===0){
            console.log("staticinfo返回的1、网格反馈统计信息是：",response.data)
            let data=response.data.data;
            let latest=data.latest.slice(-7);
            let total=data.total;
            this.latestDayFeedback=latest[latest.length-1].date.slice(0,10);

            let xaxis=[];
            let data_chart=[];
            for(let j = 0,len=latest.length; j < len; j++) {
                xaxis.push(latest[j].date.slice(5,10));
                data_chart.push(latest[j].count);
            }
            this.drawFeedback(xaxis, data_chart);
        }
        else{
            alert("staticinfo返回的1、网格反馈统计信息请求错误"+response.data.msg);
        }
      })
      .catch(function (error) {
        console.log("staticinfo返回的1、网格反馈统计信息的错误信息：",error);
      });
    
    // 访问人次数据网络请求
    axios.get('/api/admin/statistic')
      .then((response) => {
        if(response.data.code===0){
            console.log("staticinfo返回的5、访问人次数据信息是：",response.data)
            let data=response.data.data;
            let latest=data.latest.slice(-7);
            let total=data.total;
            this.latestDayVisit=latest[latest.length-1].date.slice(0,10);

            let xaxis=[];
            let data_chart=[];
            for(let j = 0,len=latest.length; j < len; j++) {
                xaxis.push(latest[j].date.slice(5,10));
                data_chart.push(latest[j].count);
            }
            this.drawVisit(xaxis, data_chart);
        }
        else{
            alert("staticinfo返回的5、访问人次数据请求错误"+response.data.msg);
        }
      })
      .catch(function (error) {
        console.log("staticinfo返回的5、访问人次数据的错误信息：",error);
      });

    // 访问网格反馈信息
    axios.get('/api/admin/feedback',{
        params: { 
          grid_id:this.gridId,
          status:0
      }
    })
      .then((response) => {
        if(response.data.code===0){
            console.log("staticinfo返回的2、网格反馈信息是：",response.data)
            let data=response.data.data;
            let list=data.list;
            let total=data.count;
            if(list){
                this.feedbackInfo=list;
                this.feedbackInfoShow=this.feedbackInfo.slice(0,3);
            }
        }
        else{
            alert("staticinfo返回的2、网格反馈信息请求错误"+response.data.msg);
        }
      })
      .catch(function (error) {
        console.log("staticinfo返回的2、网格反馈信息的错误信息：",error);
      });
    
    // 访问所有网格信息
    axios.get('/api/admin/grid/list')
      .then((response) => {
        if(response.data.code===0){
            console.log("staticinfo返回的3、所有网格信息是：",response.data)
            let data=response.data.data;
            let grids=[];
            for(let j = 0,len=data.length; j < len; j++) {
                grids.push({
                  "name":data[j].building_name+data[j].name,
                  "id":data[j].id
                });
            }
            this.allGrids=grids;
            this.allGridsShow=this.allGrids.slice(0,5);
        }
        else{
            alert("staticinfo返回的3、所有网格信息请求错误"+response.data.msg);
        }
      })
      .catch(function (error) {
        console.log("staticinfo返回的3、所有网格信息的错误信息：",error);
      });

    // 访问所有通知
    axios.get('/api/wx/notification',{
            params: { 
            grid_id: this.gridId
        }}).then((response) => {
        if(response.data.code===0){
            console.log("staticinfo返回的4、所有通知是：",response.data)
            let data=response.data.data;
            let list=data.list;
            this.notes=list;
            this.notesShow=this.notes.slice(0,3);
        }
        else{
            alert("staticinfo返回的4、所有通知请求错误"+response.data.msg);
        }
      })
      .catch(function (error) {
        console.log("staticinfo返回的4、所有通知错误信息：",error);
      });

  },

  //   定义方法
  methods:{
    //   为超级管理员的网格信息列表添加跳转链接，可跳转至列表中对应网格。
      clickToNewGrid(event){
          console.log("在StaticInfo页面点击了网格：",event);
          this.$router.push({ path: '/home/'+event});
      },

    // 绘制反馈量统计表
      drawFeedback(x_axis,data){
        // 基于准备好的dom，初始化echarts实例
        let myChart_feedback = this.$echarts.init(document.getElementById('chart-feedback'))
        let option1 = {
                xAxis: {
                    type: 'category',
                    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    data:x_axis
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: data,
                    type: 'line',
                    itemStyle : { normal: {label : {show: true}}}
                }],
                // dataZoom:[
                //         {
                //             type:'slider',//slider表示有滑动块的，inside表示内置的
                //             show:true,
                //             xAxisIndex:[0],
                //             start:0,
                //             end:50,
                //             height:10
                //         }
                //     ],
                grid:{
                    x:0,
                    y:30,
                    x2:0,
                    y2:30,
                    borderWidth:1
                },
            };
        // 绘制图表
        myChart_feedback.setOption(option1);
    },

    //   绘制访客图表
      drawVisit(x_axis, data){
          // 基于准备好的dom，初始化echarts实例
        let myChart_visit = this.$echarts.init(document.getElementById('chart-visit'))
        let option2 = {
                xAxis: {
                    type: 'category',
                    // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    data: x_axis
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: data,
                    type: 'line',
                    itemStyle : { normal: {label : {show: true}}}
                }],
                // dataZoom:[
                //         {
                //             type:'slider',//slider表示有滑动块的，inside表示内置的
                //             show:true,
                //             xAxisIndex:[0],
                //             start:0,
                //             end:50,
                //             height:10
                //         }
                //     ],
                grid:{
                    x:0,
                    y:30,
                    x2:0,
                    y2:30,
                    borderWidth:1
                },
            };
        // 绘制图表
        myChart_visit.setOption(option2);
      },

    // 点击通知详情跳转逻辑
    clickNote(event){
      console.log("在staticinfo页面点击了通知详情：",this.notesShow[event]);
      // let note_ = this.notesShow[event];
      // let str = JSON.stringify(note_);
      // //存入
      // sessionStorage.note = str;
      
      this.$router.push({ path: '/noticedetail/'+this.notesShow[event].id})
    },

    // 点击更多通知按钮逻辑
    showNotesMore(event){
      console.log('点击了“更多”按钮！')
      if(this.moreNotesFlag){
        this.notesShow=this.notes;
      }
      else{
        this.notesShow=this.notes.slice(0,3);
      }
      this.moreNotesFlag=!this.moreNotesFlag;
    },

    // 点击更多grid按钮逻辑
    showGridsMore(event){
      console.log('点击了“更多”按钮！')
      if(this.moreGridsFlag){
        this.allGridsShow=this.allGrids;
      }
      else{
        this.allGridsShow=this.allGrids.slice(0,5);
      }
      this.moreGridsFlag=!this.moreGridsFlag;
    },

    // 点击了更多反馈信息按钮逻辑
    showFeedbackInfoMore(event){
      console.log('点击了“更多”按钮！')
      if(this.moreFeedbackInfoFlag){
        this.feedbackInfoShow=this.feedbackInfo;
      }
      else{
        this.feedbackInfoShow=this.feedbackInfo.slice(0,3);
      }
      this.moreFeedbackInfoFlag=!this.moreFeedbackInfoFlag;
    },

  }

}
</script>

<style scoped>
/* 整个组件的字体 */
.font-all {
  font-size: 14px;
  /* font-family: Microsoft JhengHei; */
  /* font-weight: bold; */
  /* color: #0099ff; */
}

/* 标题 */
.title-top {
  font-size: 16px;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  /* align-items: baseline; */
  justify-content: center;
  
  font-weight: bold;
  line-height: 1.14rem;
  color: #0099ff;
}
.title-top img{
  width: 38px;
  height: 30px;
}

/* 最上面的卡片 */
.person-card-info{
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-content: center;
    background-color: #0099ff;
    padding: 10px 20px;
    border-radius: 10px;
}
.person-card-info .person-card-info-name{
    color: white;
    padding: 2px;
    font-size: 16px;
}
.person-card-info .person-card-info-detail{
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
}
.person-card-info .person-card-info-detail img{
    margin: 0 5px 0 0;
    height: 35px;
}
.person-card-info-detail-3{
    margin: 4px 0;
}

/* 统一的每个卡片的格式 */
.card{
    background-color: white;
    border-radius: 10px;
    margin: 10px;
}
.card .title{
    color: white;
    background-color: #0099ff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;
}

/* 反馈信息数量统计折线图 */
.chart-all{
    margin: 0 0 0 20px;
    width: 300px;
    height: 130px;
}



/* 所有网格条目样式 */
.all-grid{
    margin: 4px 0 2px 20px;
    color: #666666;
}


/* 所有通知 */
.note-info-bottom{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  margin: 10px;
}
.note-info-bottom .note-info-bottom-order{
  background-color: #0099ff;
  color: white;
  border-radius: 0.05rem;
  line-height: 0.37rem;
  text-align: center;
  width: 12px;
  height: 12px;
  display: table;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.note-info-bottom .note-info-bottom-text{
  margin: 0 10px ;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: center;
  flex: 1;
  color: #666666;
}
.note-info-bottom .note-info-bottom-text .note-info-bottom-text1{
  margin: 0 0 10px 0;
}
.note-info-bottom .note-info-bottom-text234{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  width: auto;
  width: -webkit-fill-available;
}
.note-info-bottom .note-info-bottom-text234 .note-info-bottom-text2{
  flex: 1;
  text-align: left;
  color:#0098ff
}
.note-info-bottom .note-info-bottom-text234 .note-info-bottom-text3{
  flex: 1;
  text-align: right;
  color: #999999;
}
.note-info-bottom .note-info-bottom-text234 .van-icon-clock-o{
  margin: 0 4px 0 10px;
  
}
.note-info-bottom .note-info-bottom-text234 .note-info-bottom-text4{
  color: #999999;
}
.note-info-more{
  color: #089BFF;
  font-weight: bold;
  text-align: right;
  margin: 0 30px;
}

/* 没有信息的时候展示文字 */
.info-none{
  color: #666;
  font-weight: bold;
  padding: 10px 20px;
}

/* chart-info折线图的说明 */
.chart-info{
    margin: 5px 0 0 20px;
    color: #666666;
}


/* 行内行高 */
.van-icon::before {
  display: inline-block;
  line-height: 0.48333rem;
}
</style>