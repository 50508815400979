<template>
  <div class="gridperson font-all">
    <div class="title">网格人员信息</div>

    <div class="person-card" id="v-for-person" v-for="(item, index) in gridPersons" :key="'person-'+index">
        <img class="person-card-img" v-bind:src="item.avatar.length==0 ? gridPersonDefaultAvatar:item.avatar" alt="gridperson-avatar.png">
        <!-- <img class="person-card-img" v-bind:src="gridPersonDefaultAvatar" alt="gridperson-avatar.png"> -->
        <div class="person-card-info">
            <div class="person-card-info-name">姓名：{{item.name}}</div>
            <div class="person-card-info-detail">
                <img src="../assets/gridperson-divider.png" alt="gridperson-divider.png">
                <div class="person-card-info-detail-3">
                    <div>职务：{{item.type}}</div>
                    <div>部门：{{department}}</div>
                    <div v-if="userRole==='admin'">
                        <a :href="'tel:'+item.phone" style="color: black;">
                            电话：{{item.phone}}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="duty-head title-small">
        <img src="../assets/gridperson-divider.png" alt="gridperson-divider.png">
        职责详情
    </div>

    <div class="duty-info" id="v-for-duty" v-for="(item, index) in dutys" :key="'duty-'+index">
        <div class="duty-info-order">{{index+1}}</div>
        <div class="duty-info-text">
            {{item}}
        </div>
    </div>

    <div class="relate-grid-head title-small">
        <img src="../assets/gridperson-divider.png" alt="gridperson-divider.png">
        关联网格
    </div>

    <div class="relate-grid-detail" v-for="(item, index1) in gridPersons" :key="'grids-name-'+index1">
        <div class="relate-grid-detail-name">{{item.name}}</div>
        <div class="relate-grid-detail-grids">
            <!-- <div v-for="(grid, index2) in item.grids.slice(0,5)" :key="'grid-'+index2"><span>{{grid.name}}</span><span>({{grid.type}})</span></div>
            <div v-if="item.grids.length>5" v-on:click="clickGridMore(index1)"><span>...</span></div> -->
            <div v-for="(grid, index2) in item.grids" :key="'grid-'+index2"><span v-on:click="clickToNewGrid(grid.id)">{{grid.name}}</span><span>({{grid.type}})</span></div>
        </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'

export default {
  name: 'GridPerson',
  data(){
      return {
        userRole:"",
        person:"",
        gridId:"",
        department:"",
        gridPersonDefaultAvatar:require("../assets/gridperson-avatar.png"),
        gridPersons:[],
        dutys:[],
      }
    },
  components: {
  },

  //   组件创建之前调用钩子
  created:function (){
      let person = this.$route.params.person
      let gridid = this.$route.params.gridid
      console.log("gridperson网格人员部门是：",this.$route.query.department)
      console.log("gridperson请求人员的身份是：",this.$route.query.userrole)
      console.log("gridperson网格人员是：",person)
      console.log("gridperson网格id是：",gridid)
      this.person=person;
      this.gridId=gridid;
      this.department=this.$route.query.department;
      this.userRole=this.$route.query.userrole;
    //   this.userRole='user';
      this.fetchData();
  },

  //   定义方法
  methods:{
    //   前端添加网格名称的跳转链接，在“关联网格”中点击网格名称链接可跳转。
      clickToNewGrid(event){
          console.log("在gridPerson页面点击了网格：",event);
          this.$router.push({ path: '/home/'+event});
      },

    //   请求网络信息
      fetchData () {

      // 请求网格人员信息
      axios.get("/api/wx/grid/person",{
          params:{
              grid_id:this.gridId,
              type:this.person
          }
      })
      .then((response) => {
        if(response.data.code===0){
            console.log("gridperson返回的网格人员信息是：",response.data)
            let data=response.data.data;
            this.gridPersons=data.persons;
            this.dutys=data.duties;
        }
        else{
            alert(response.data.msg);
        }
      })
      .catch(function (error) {
        console.log("gridperson错误信息：",error);
      });
    
    },

    // 点击了grid的三个点...
    // clickGridMore(event){
    //     console.log("点击了三个点，点击的人员是：",event)
    //     let person_=this.gridPersons[event];
    //     let all_grids_arr=person_.grids;
    //     let all_grids_string=all_grids_arr.join(";")+";职务是"+person_.type;
    //     alert(all_grids_string)
    // },

  },
}
</script>

<style scoped>
/* 整体样式字体 */
.font-all{
    font-size: 14px;
}

/* 标题 */
.title{
    font-size: 20px;
    text-align: center;
    color: #0099ff;
    font-weight: bold;
    margin: 10px auto;
}

/* 人员信息卡 */
.person-card{
    display: flex;
    flex-direction: row;
    width: auto;
    margin: 10px 10px;
    background-color: white;
    /* justify-content: center;
    align-items: center; */
    height: fit-content;
    border-radius: 10px;
}
.person-card .person-card-img{
    width: 90px;
    height: 90px;
    margin: 10px;
}
.person-card .person-card-info{
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-content: center;
}
.person-card .person-card-info .person-card-info-name{
    background-color: #0099ff;
    color: white;
    border-radius: 10px;
    padding: 2px;
    margin: 0 0 10px 0;
    text-align: center;
}
.person-card .person-card-info .person-card-info-detail{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.person-card .person-card-info .person-card-info-detail img{
    margin: 0 15px 0 0;
    height: 50px;
}
.person-card-info-detail-3{
    margin: 4px 0;
}

/* 小标题 */
.title-small{
    font-size: 16px;
    color: #0099ff;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    margin: 10px;
    align-items: center;
}
.title-small img{
    height: 16px;
    width: 4px;
    margin: 0 5px 0 0;
}

/* 职责条目样式 */
.duty-info{
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  align-items: baseline;
  margin: 10px 20px;
  background-color: white;
  border-radius: 10px;
  padding: 4px 0 4px 10px;
}
.duty-info .duty-info-order{
  background-color: #0099ff;
  color: white;
  padding: 1px;
  text-align: center;
  width: 12px;
  height: 12px;
  display: table;
  align-items: center;
  justify-content: center;
  border-radius: 0.05rem;
  line-height: 0.37rem;
  flex-shrink: 0;
}
.duty-info .duty-info-text{
    margin: 2px 10px;
}

/* 关联网格样式 */
.relate-grid-detail{
    background-color: white;
    color: white;
    margin: 10px 0;
    padding: 10px 0;
}
.relate-grid-detail .relate-grid-detail-name{
    margin: 10px 20px;
    background-color: #0099ff;
    padding: 10px 0;
    border-radius: 10px;
    text-align: center;
}
.relate-grid-detail .relate-grid-detail-grids{
    font-size: 10px;
    display: flex;
    flex-direction: row;
    margin: 10px 20px;
    flex-shrink: 0;
    overflow-x: scroll;
}
.relate-grid-detail .relate-grid-detail-grids div{
    text-align: center;
    background-color: #0099ff;
    padding: 30px 8px;
    border-radius: 10px;
    text-align: center;
    margin: 0 2px;
    flex: 1;
}
.relate-grid-detail .relate-grid-detail-grids span{
    display: block;
    white-space: nowrap;
}
</style>
